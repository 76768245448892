@include block('pighunt_container') {
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
}

@include block('pighunt_card') {
  position: relative;
  background: #f2f2f2;
  border-radius: 4px;
  height: 200px;
  width: 100%;
  margin-bottom: 8px;
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  @include media-breakpoint-up(lg) {
    height: 300px;
  }

  @include element('banner') {
    position: relative;
    top: 0px;
    left: 0px;
    right: 0px;
    background: #f2f2f2;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .bg-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }

    .front-image {
      width: 50%;
      height: 40%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      @include media-breakpoint-up(lg) {
        width: 40%;
      }
    }

    @include element('content') {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-45%, -10%);
      text-align: center;
      background: linear-gradient(45deg, #ffd700, #ffc107, #ff9800, #ffd700);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      z-index: 2;
    }
    @include element('amount') {
      font-size: 28px;
      font-weight: 600;
      background: linear-gradient(45deg, #ffd700, #ffc107, #ff9800, #ffd700);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      z-index: 1;
      @include media-breakpoint-up(lg) {
        font-size: 48px;
      }
    }
    @include element('amount_unit') {
      margin-left: 6px;
      font-size: 20px;
      font-weight: normal;
      background: linear-gradient(45deg, #ffd700, #ffc107, #ff9800, #ffd700);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      z-index: 1;
      @include media-breakpoint-up(lg) {
        font-size: 28px;
      }
    }
  }
}

@include block('pighunt_header') {
  @include element('title') {
    margin: 18px 6px 6px;
    width: 100%;
    font-weight: normal;
    font-size: 34px;
    line-height: 34px;
    align-items: center;
    text-align: center;
    color: $--color-greyscale-600;
  }
  @include element('date') {
    margin: 10px 5px;
    width: 100%;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: $--color-greyscale-300;
  }
}
